import Image from 'next/image';
import Link from 'next/link';
import RightTick from '@/assets/Icons/Homepage/right-white-tick.svg';
import YoutubeIcon from '@/assets/Icons/Homepage/youtube-color.svg';
import PopularBatchGradient from '@/assets/Images/Banner/bg-popular-batch-gradient.png';
import PopularBatchesBanner from '@/assets/Images/Banner/bg-popular-batches-banner.png';
import { batchData } from '@/constant/BatchData';
import { youtubeChannel, youtubeLink } from '@/constant/CommonLink';
import { Slider } from '@/HOC/SliderComponent';
import { SwiperSlide } from 'swiper/react';

const listItems = [
  { id: 1, text: 'Paced Learning' },
  { id: 2, text: 'Exam-Focused' },
  { id: 3, text: 'Recorded Sessions' },
];

const PopularBatches = () => {
  const { images } = batchData.sambhavBatch;
  return (
    <section className="relative my-10 w-full">
      <Image src={PopularBatchGradient} alt="Popular Batches banner" className="absolute bottom-0 -z-50" />
      <div className="font-inter lg:mx-auto lg:w-4/5 lg:max-w-screen-xl ">
        <div className="flex w-full flex-col items-start">
          <h2 className="text-2xl font-semibold text-primary-green md:text-4xl">Popular Batches</h2>
          <p className="mt-2 w-3/5 text-base font-normal tracking-tighter text-secondary-slate-gray">
            Medway offers organized batches to ensure personalized attention, effective learning, and steady progress,
            allowing each student to excel at their own pace.
          </p>
        </div>
        <div className="mt-10 flex items-end">
          {/* Left Section */}
          <div className="mt-6 w-full">
            <Image src={PopularBatchesBanner} height={529} alt="Popular Batches" className="" />
          </div>
          {/* Right Section */}
          <div className="relative w-full self-start md:w-1/2">
            <small className="mb-3 text-lg font-normal text-slate-gray">Dive deep into</small>
            <h2 className="text-4xl font-semibold text-secondary-green">Sambhav Batch</h2>
            <ul className="my-6 mb-14 flex w-5/6 items-center justify-between space-y-2 text-gray-600 ">
              {listItems.map((item) => (
                <li key={item.id} className="flex items-center">
                  <Image
                    src={RightTick}
                    alt="Right Tick"
                    width={20}
                    height={20}
                    className="mr-2 rounded-full bg-secondary-green p-1"
                  />
                  <small className="text-sm text-slate-gray">{item.text}</small>
                </li>
              ))}
            </ul>
            {/* Swiper Slider */}
            <Slider
              options={{
                navigation: {
                  nextEl: '#custom-batch-next',
                  prevEl: '#custom-batch-prev',
                },
                spaceBetween: 15,
                slidesPerView: 2.2,
              }}
            >
              {images.map((image, index) => (
                <SwiperSlide key={image.url}>
                  <Link
                    href={`${youtubeLink}/${image.url}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex size-full items-center gap-4 rounded-lg border"
                  >
                    <Image
                      src={image.src}
                      alt={`Thumbnail ${index + 1}`}
                      width={280}
                      height={280}
                      className="h-36 rounded-xl"
                    />
                  </Link>
                </SwiperSlide>
              ))}
            </Slider>

            <div className="mt-8 flex items-center gap-x-2">
              <p className="text-sm">Explore more videos on : </p>
              <Link
                href={youtubeChannel}
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-x-0.5 rounded-2xl border-2 border-secondary-green px-3 py-2 hover:scale-105"
              >
                <Image src={YoutubeIcon} alt="Medway edu youtube channel" className="w-5" />
                <p className="text-xs font-semibold leading-tight">YouTube</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PopularBatches;
