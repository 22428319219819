import { SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';

import Image from 'next/image';
import PlayStoreImage from '@/assets/Logo/play-store.png';
import { testimonials } from '@/constant/HomepageConst';
import { Slider } from '@/HOC/SliderComponent';

const Testimonials = () => {
  return (
    <section className="my-10 bg-secondary-light-gray py-12 font-inter">
      <div className="mx-auto text-center lg:w-4/5 lg:max-w-screen-xl">
        <small className="block text-lg font-medium text-primary-green">Our Students Feedback</small>
        <strong className="text-3xl font-semibold text-black">Nothing less than excellent</strong>
        <div className="my-8 flex items-center justify-center">
          <Image src={PlayStoreImage} alt="Google Play" width={500} height={500} className="h-8 w-28 border-none" />
          <p className="ml-2 text-sm font-medium text-black">
            4.4 <span className="text-lg text-secondary-green">★</span>{' '}
          </p>
        </div>
        <Slider
          options={{
            navigation: {
              nextEl: '#custom-testimonial-next',
              prevEl: '#custom-testimonial-prev',
            },
            spaceBetween: 20,
            slidesPerView: 3.2,
          }}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="mb-1 flex h-60 max-w-sm flex-col justify-between rounded-xl border border-secondary-light-gray bg-white px-6 py-4 text-left font-poppins shadow-lg">
                <p className="text-base leading-relaxed text-gray-800">{testimonial.review}</p>
                <div className="flex items-center">
                  <Image src={testimonial.img} alt={testimonial.name} className="mr-4 size-12 rounded-full" />
                  <div>
                    <h4 className="font-medium text-dark-gray">{testimonial.name}</h4>
                    <p className="text-sm text-secondary-slate-gray">{testimonial.details}</p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
