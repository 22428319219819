import MasterPlanImage from '@/assets/Images/Plans/master-plan-image.png';
import OtherPlanImage from '@/assets/Images/Plans/other-plan-image.png';
import SpecialPlanImage from '@/assets/Images/Plans/special-plan-image.png';
import SubjectPlanImage from '@/assets/Images/Plans/subject-plan-image.png';

export const plansData = [
  {
    planType: 'MASTER PLAN',
    planTitle: 'Video+ Qbank+ Test',
    courses: [
      {
        id: 36,
        title: '1st Year Pro Plan',
        subTitle: '',
        isSelect: false,
        discountedPrice: 3799,
        originalPrice: 4749,
        discount: 20,
        expiryDate: 'June 09 2025',
        planDuration: [
          {
            id: 36,
            title: '6 Months',
            originalPrice: 4749,
            discountedPrice: 3799,
            isSelect: false,
            discount: 20,
            expiryDate: 'June 09 2025',
          },
          {
            id: 11,
            title: '12 Months',
            originalPrice: 7250,
            discountedPrice: 5800,
            isSelect: false,
            discount: 20,
            expiryDate: 'December 09 2025',
          },
        ],
        coveredPlanIds: [16, 17, 18],
      },
      {
        id: 37,
        title: '2nd Year Pro Plan',
        subTitle: '',
        isSelect: false,
        discountedPrice: 3280,
        originalPrice: 4100,
        discount: 20,
        expiryDate: 'June 09 2025',
        planDuration: [
          {
            id: 37,
            title: '6 Months',
            originalPrice: 4100,
            discountedPrice: 3280,
            isSelect: false,
            discount: 20,
            expiryDate: 'June 09 2025',
          },
          {
            id: 12,
            title: '12 Months',
            originalPrice: 6500,
            discountedPrice: 5200,
            isSelect: false,
            discount: 20,
            expiryDate: 'December 09 2025',
          },
        ],
        coveredPlanIds: [19, 20, 22],
      },
      {
        id: 38,
        title: '3rd Year Pro Plan',
        subTitle: '',
        isSelect: false,
        discountedPrice: 2560,
        originalPrice: 3200,
        discount: 20,
        expiryDate: 'June 09 2025',
        planDuration: [
          {
            id: 38,
            title: '6 Months',
            originalPrice: 3200,
            discountedPrice: 2560,
            isSelect: false,
            discount: 20,
            expiryDate: 'June 09 2025',
          },
          {
            id: 13,
            title: '12 Months',
            originalPrice: 4500,
            discountedPrice: 3600,
            isSelect: false,
            discount: 20,
            expiryDate: 'December 09 2025',
          },
        ],
        coveredPlanIds: [21, 23, 24, 25],
      },
      {
        id: 39,
        title: '4th Year Pro Plan',
        subTitle: '',
        isSelect: false,
        discountedPrice: 4800,
        originalPrice: 6000,
        discount: 20,
        expiryDate: 'June 09 2025',
        planDuration: [
          {
            id: 39,
            title: '6 Months',
            originalPrice: 6000,
            discountedPrice: 4800,
            isSelect: false,
            discount: 20,
            expiryDate: 'June 09 2025',
          },
          {
            id: 14,
            title: '12 Months',
            originalPrice: 7900,
            discountedPrice: 6320,
            isSelect: false,
            discount: 20,
            expiryDate: 'December 09 2025',
          },
        ],
        coveredPlanIds: [26, 27, 28, 29, 30, 31, 32, 33],
      },
      {
        id: 40,
        title: 'Intern Plan',
        subTitle: '',
        isSelect: false,
        discountedPrice: 9600,
        originalPrice: 12000,
        discount: 20,
        expiryDate: 'June 09 2025',
        planDuration: [
          {
            id: 40,
            title: '6 Months',
            originalPrice: 12000,
            discountedPrice: 9600,
            isSelect: false,
            discount: 20,
            expiryDate: 'June 09 2025',
          },
          {
            id: 15,
            title: '12 Months',
            originalPrice: 14999,
            discountedPrice: 11999,
            isSelect: false,
            discount: 20,
            expiryDate: 'December 09 2025',
          },
          {
            id: 41,
            title: '18 Months',
            originalPrice: 17999,
            discountedPrice: 14399,
            isSelect: false,
            discount: 20,
            expiryDate: 'June 09 2026',
          },
          {
            id: 42,
            title: '24 Months',
            originalPrice: 22000,
            discountedPrice: 17600,
            isSelect: false,
            discount: 20,
            expiryDate: 'December 09 2026',
          },
        ],
        coveredPlanIds: [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33],
      },
    ],
  },
  {
    planType: 'SUBJECT PLAN',
    planTitle: 'Video+ Qbank+ Test',
    courses: [
      {
        id: 18,
        title: 'Biochemistry',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1119,
        originalPrice: 1399,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 19,
        title: 'Microbiology',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1799,
        originalPrice: 2249,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 20,
        title: 'Pathology',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1699,
        originalPrice: 2124,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 21,
        title: 'Forensic Medicine and Toxicology',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1299,
        originalPrice: 1624,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 22,
        title: 'Pharmacology',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1999,
        originalPrice: 2499,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 23,
        title: 'Community Medicine (PSM)',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1299,
        originalPrice: 1624,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 24,
        title: 'Ophthalmology',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1799,
        originalPrice: 2249,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 25,
        title: 'ENT (Ear, Nose, and Throat)',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1799,
        originalPrice: 2249,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 26,
        title: 'Medicine',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1599,
        originalPrice: 1999,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 27,
        title: 'Obstetrics and Gynaecology',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1399,
        originalPrice: 1749,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 28,
        title: 'Paediatrics',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1399,
        originalPrice: 1749,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 29,
        title: 'Anaesthesia',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1299,
        originalPrice: 1624,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 30,
        title: 'Dermatology',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1199,
        originalPrice: 1499,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 31,
        title: 'Psychiatry',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1099,
        originalPrice: 1374,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 32,
        title: 'Orthopaedics',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1599,
        originalPrice: 1999,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 33,
        title: 'Radiology',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1699,
        originalPrice: 2124,
        discount: 20,
        expiryDate: ['December 09 2025'],
      },
      {
        id: 46,
        title: 'Surgery',
        subTitle: '',
        isSelect: false,
        discountedPrice: 1599,
        originalPrice: 1999,
        discount: 20,
        expiryDate: ['September 09 2025'],
      },
    ],
  },
  {
    planType: 'OTHER PLAN',
    planTitle: 'Video+ Qbank+ Test',
    courses: [
      {
        id: 35,
        title: 'SAMBHAV Live Batch',
        subTitle: 'Sharp from 2024-08-24 valid till 2024-12-31.',
        isSelect: false,
        discountedPrice: 9100,
        originalPrice: 25999,
        discount: 65,
        expiryDate: ['December 31 2024'],
      },
      {
        id: 43,
        title: 'QBank + Test Series (6 months)',
        subTitle: 'Sharp from 2024-09-09 valid till 2025-03-10.',
        isSelect: false,
        discountedPrice: 2639,
        originalPrice: 3299,
        discount: 20,
        expiryDate: ['March 10 2025'],
      },
      {
        id: 44,
        title: 'QBank + Test Series (18 months)',
        subTitle: 'Sharp from 2024-09-09 valid till 2026-03-10.',
        isSelect: false,
        discountedPrice: 4320,
        originalPrice: 5400,
        discount: 20,
        expiryDate: ['March 10 2026'],
      },
      {
        id: 45,
        title: 'QBank + Test Series (24 months)',
        subTitle: 'Sharp from 2024-09-09 valid till 2026-09-09.',
        isSelect: false,
        discountedPrice: 4799,
        originalPrice: 5999,
        discount: 20,
        expiryDate: ['September 09 2026'],
      },
      {
        id: 47,
        title: 'Test Series',
        subTitle: 'Sharp from 2024-10-07 valid till 2025-10-06.',
        isSelect: false,
        discountedPrice: 1279,
        originalPrice: 1599,
        discount: 20,
        expiryDate: ['October 06 2025'],
      },
    ],
  },
  {
    planType: 'SPECIAL PLAN',
    planTitle: 'Medway Specials',
    courses: [
      {
        id: 50,
        title: 'Sambhav Rapid Revision',
        subTitle: 'Sharp from 2024-11-06 valid till 2025-07-24.',
        isSelect: false,
        discountedPrice: 2950,
        originalPrice: 5000,
        discount: 41,
        expiryDate: ['July 24 2025'],
      },
    ],
  },
];

export const staticPlansData: any = {
  'MASTER PLAN': {
    image: MasterPlanImage,
    description:
      'Our Master Plans are tailored for each year of your medical journey, providing comprehensive resources for success.',
  },
  'SUBJECT PLAN': {
    image: SubjectPlanImage,
    description: 'Focus on specific subjects with detailed plans designed to enhance your understanding and retention.',
  },
  'OTHER PLAN': {
    image: OtherPlanImage,
    description: 'Explore additional plans like live batches and test series to stay ahead in your preparation.',
  },
  'SPECIAL PLAN': {
    image: SpecialPlanImage,
    description: 'Exclusive Medway specials crafted for rapid revisions and targeted learning.',
  },
};
