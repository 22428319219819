// pages/our-faculties.js
import React from 'react';
import { facultiesData } from '@/constant/CommonData';
import { Slider } from '@/HOC/SliderComponent';
import { SwiperSlide } from 'swiper/react';

import FacultyCard from '../common/FacultyCard';

const OurFaculties = () => {
  return (
    <div className="mx-auto px-4 py-8 lg:w-4/5 lg:max-w-screen-xl">
      <h2 className="text-center text-2xl font-bold text-green-600">Our Faculties</h2>
      <p className="mx-auto mt-2 max-w-2xl text-center text-gray-600">
        At Medway, our dedicated faculty combines expertise and passion to nurture future medical professionals.
      </p>
      <div className="mt-8">
        <Slider
          options={{
            navigation: {
              nextEl: '#custom-faculty-next',
              prevEl: '#custom-faculty-prev',
            },
            spaceBetween: 10,
            slidesPerView: 3,
          }}
        >
          {facultiesData.map((faculty, index) => {
            const gradient =
              index % 2 === 0
                ? 'bg-gradient-to-br from-white via-transparent to-[#468837]'
                : 'bg-gradient-to-br from-white via-transparent to-[#eaa7f6]    ';

            return (
              <SwiperSlide key={faculty.id}>
                <FacultyCard {...faculty} gradient={gradient} />
              </SwiperSlide>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default OurFaculties;
