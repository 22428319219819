import { useState } from 'react';
import Image from 'next/image';
import Link from 'next/link';
import YoutubeIcon from '@/assets/Icons/Homepage/youtube-color.svg';
import { batchData } from '@/constant/BatchData';
import { instagramData } from '@/constant/HomepageConst';

const MediaPresence = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="bg-secondary-light-gray px-4 py-8 font-inter">
      <div className="lg:mx-auto lg:w-4/5 lg:max-w-screen-xl">
        <h2 className="mb-2 text-center text-3xl font-semibold text-primary-green">Media Presence</h2>
        <p className="mb-8 text-center text-base text-dark-gray">
          Explore Medway&apos;s educational content on YouTube and Instagram Shorts.
        </p>

        {/* YouTube Section */}
        <div className="mb-12">
          <div className="mb-4 flex items-center">
            <Image src={YoutubeIcon} alt="@Medwayedu Channel" />
            <span className="ml-2 text-base font-semibold">@Medwayedu</span>
          </div>

          {Object.values(batchData)
            .slice(0, showMore ? Object.values(batchData).length : 2)
            .map((batch, index) => (
              <div key={index} className="mb-6">
                <p className="mb-2 text-sm font-medium text-dark-gray">{batch.name}</p>
                <div className="grid grid-cols-4 gap-4">
                  {batch.images.map((item, i) => (
                    <Link key={i} href={item.url} target="_blank" rel="noopener noreferrer">
                      <Image
                        src={item.src}
                        alt="YouTube Thumbnail"
                        width={500}
                        height={500}
                        className="rounded-lg shadow-lg transition-transform hover:scale-105"
                      />
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          {/* Show More Button */}
          {Object.values(batchData).length > 2 && (
            <div className="text-center">
              <button
                onClick={() => setShowMore(!showMore)}
                className="mt-4 rounded-full border border-green-600 bg-secondary-green/20 px-4 py-2 text-sm text-secondary-green hover:bg-green-200"
              >
                {showMore ? 'Show Less' : 'View More'}
              </button>
            </div>
          )}
        </div>

        {/* Instagram Section */}
        <div>
          <div className="mb-4 flex items-center">
            <span className="text-lg font-semibold">Instagram</span>
          </div>
          <div className="grid grid-cols-4 gap-4">
            {instagramData.map((item, index) => (
              <Link key={index} href={item.url} target="_blank" rel="noopener noreferrer">
                <Image
                  src={item.src}
                  alt="Medway Batches"
                  width={800}
                  height={800}
                  className="rounded-lg object-fill shadow-lg transition-transform hover:scale-105"
                />
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaPresence;
