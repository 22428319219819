import React from 'react';
import Link from 'next/link';

interface CourseCardProps {
  title: string;
  description: string;
  link: string;
}

const CourseCard: React.FC<CourseCardProps> = ({ title, description, link }) => {
  return (
    <div className="relative h-72 w-[22.8rem] bg-light-yellow py-5 pl-8 pr-2 font-inter shadow-lg drop-shadow">
      <div className="absolute right-0 top-0 size-14 overflow-hidden bg-secondary-green">
        <div className="absolute -top-3 right-[-4.28rem] size-24 origin-top-right rotate-45 bg-white"></div>
      </div>

      {/* Card Content */}
      <h3 className="w-3/4 text-2xl font-bold text-primary-green">{title}</h3>
      <p className="py-5 text-sm font-normal leading-loose text-black">{description}</p>
      <Link href={link} className="flex items-center font-medium text-secondary-green hover:scale-90">
        Read more <span className="ml-2">→</span>
      </Link>
    </div>
  );
};

export default CourseCard;
