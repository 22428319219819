import React, { useState } from 'react';
import Image from 'next/image';
import BgPlanGradient from '@/assets/Images/Banner/bg-plan-gradient.png';
import { plansData } from '@/constant/PlansData';

import PlanCard from '../common/PlanCard';

const PlansOffered = () => {
  const [activeTab, setActiveTab] = useState('MASTER PLAN');

  const activePlans = plansData.find((plan) => plan.planType === activeTab);

  return (
    <div className="mx-auto my-10 w-4/5 px-10 py-5 font-inter lg:max-w-screen-xl">
      <h2 className="text-center text-3xl font-bold text-primary-green">Our Plans</h2>
      <p className="mt-2 text-center text-base font-normal text-dark-gray">
        Explore our tailored plans designed to support your unique study needs and exam goals.
      </p>
      {/* Tabs */}
      <div className="relative my-8">
        {/* Gradient Background */}
        <Image
          src={BgPlanGradient}
          alt="Plan gradient"
          width={1000}
          height={250}
          className="absolute -bottom-28 left-20 -z-10 opacity-80"
        />

        {/* Card Container */}
        <div className="relative z-10 rounded-lg border bg-white shadow-lg">
          {/* Tabs */}
          <div className="flex justify-around rounded-t-lg bg-primary-gray text-base">
            {plansData.map((tab) => (
              <button
                key={tab.planType}
                onClick={() => setActiveTab(tab.planType)}
                className={`size-full rounded-t-lg p-4 ${
                  activeTab === tab.planType
                    ? 'bg-white font-semibold text-secondary-green'
                    : 'font-medium text-dark-gray'
                }`}
              >
                {tab.planType}
              </button>
            ))}
          </div>

          {/* Plan Cards */}
          <div className="mt-2 flex flex-col gap-y-6 py-4">
            {activePlans?.courses
              .slice(0, 5)
              .map((plan) => (
                <PlanCard
                  key={plan.id}
                  plan={plan.title}
                  originalPrice={plan.originalPrice}
                  discountedPrice={plan.discountedPrice}
                  discount={plan.discount}
                />
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlansOffered;
