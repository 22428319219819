import React from 'react';
import Image from 'next/image';
import ServicesBannerImage from '@/assets/Images/Banner/medway-services-banner.png';

const ServicesBanner = () => {
  return (
    <section className="my-10 lg:mx-auto lg:w-4/5 lg:max-w-screen-xl">
      <Image src={ServicesBannerImage} width={1200} height={600} alt="Medway Online Services" />
    </section>
  );
};

export default ServicesBanner;
