'use client';

import CoursesOffered from '@/components/HomePage/CouresOffered';
import FeaturesSection from '@/components/HomePage/FeatureSection';
import HeroSection from '@/components/HomePage/HeroSection';
import OurFaculties from '@/components/HomePage/OurFaculties';
import PlansOffered from '@/components/HomePage/PlansOffered';
import PopularBatches from '@/components/HomePage/PopularBatches';
import ServicesBanner from '@/components/HomePage/ServicesBanner';
import Testimonials from '@/components/HomePage/Testimonials';
import TestSeries from '@/components/HomePage/TestSeries';

import MediaPresence from '../components/HomePage/MediaPresence';

export default function Page() {
  return (
    <section>
      <HeroSection />
      <FeaturesSection />
      <PlansOffered />
      <ServicesBanner />
      <CoursesOffered />
      <TestSeries />
      <OurFaculties />
      <PopularBatches />
      <MediaPresence />
      <Testimonials />
    </section>
  );
}
