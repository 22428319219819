import React from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { FacultyCardProps } from '@/types/const';

const FacultyCard = ({
  name,
  department,
  experience,
  description,
  image,
  gradient,
}: FacultyCardProps & { gradient: string }) => {
  return (
    <div className="relative mb-1 flex h-[21.5rem] max-w-[22.5rem] flex-col justify-between rounded-lg border border-black bg-white font-inter shadow-md">
      <div className={`absolute bottom-0 right-0 z-10 size-56 ${gradient} rounded-lg`}></div>
      <i className="px-6 pb-2 pt-4 text-sm font-medium leading-relaxed tracking-wider text-slate-gray">{description}</i>
      <Link href="#" className="px-6 text-green-500">
        Know more →
      </Link>
      <div className="z-20 mt-4 flex h-40 items-end justify-between">
        <div className="pb-4 pl-5">
          <h3 className="text-base font-semibold">{name}</h3>
          <p className="text-xs text-gray-500">{department}</p>
          <p className="text-[0.7rem] tracking-tighter text-gray-500">Working Experience - {experience}</p>
        </div>
        <Image src={image} alt={name} className="self-end rounded-br-lg" />
      </div>
    </div>
  );
};

export default FacultyCard;
