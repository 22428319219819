import React from 'react';
import Image from 'next/image';
import FreeTrialBanner from '@/assets/Images/homepage-free-trial.png';

const HeroSection = () => {
  return (
    <section className="mb-12 flex w-full flex-col items-center justify-center bg-custom-gradient px-6 pt-20 text-center font-inter ">
      <h2 className="pt-12 text-4xl font-semibold tracking-tight text-dark-green md:text-6xl">
        Your Pathway to Medical Success
      </h2>
      <p className="w-3/5 py-5 text-center leading-loose text-dark-green">
        Medway prepares aspiring medical professionals for NEXT, NEET PG, and FMGE exams, offering quality education,
        innovative teaching, and personalized guidance to equip students with the confidence and skills to excel.
      </p>
      <button className="mb-12 mt-4 rounded-full bg-primary-green px-6 py-3 text-white shadow-lg hover:bg-green-600">
        Start your Free Trial
      </button>
      <div className="flex justify-center space-x-4">
        <Image src={FreeTrialBanner} alt="Mockup 1" width={700} height={400} />
      </div>
    </section>
  );
};

export default HeroSection;
