import React from 'react';

const PlanCard = ({ plan, originalPrice, discountedPrice, discount, isCenter = true }: any) => {
  return (
    <div className={`flex w-full items-center ${isCenter ? 'text-center' : 'text-start'} `}>
      <p className="basis-1/3 px-4 text-base font-medium text-dark-gray">{plan}</p>
      <div className="flex w-full basis-1/4 items-center justify-center gap-x-6 text-center text-base font-medium">
        <p className="text-dark-gray line-through">₹{originalPrice}</p>
        <p className="text-secondary-green">₹{discountedPrice}</p>
      </div>
      <div className="flex basis-2/5 items-center gap-x-4 text-center">
        <p className="basis-1/2 rounded text-sm">
          <span className="rounded bg-primary-green px-4 py-1.5 text-white ">{discount}% OFF</span>
        </p>
        <button className="rounded-full border border-primary-green px-4 py-1.5 text-sm font-medium text-secondary-green ">
          View Details
        </button>
      </div>
    </div>
  );
};

export default PlanCard;
