import { Swiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/grid';

import {
  Autoplay,
  FreeMode,
  Navigation,
  //   Pagination,
  //   Grid,
} from 'swiper/modules';

import { SliderProps } from '@/types/const';

export const Slider = ({ children, options, className = 'w-full', swiperRef }: SliderProps) => {
  const defaultSwiperOptions = {
    spaceBetween: 10,
    centeredSlides: false,
    modules: [Navigation, Autoplay, FreeMode],
    freeMode: true,
    cssMode: true,
  };

  const swiperOptions = {
    ...defaultSwiperOptions,
    ...options,
  };

  return (
    <Swiper {...swiperOptions} className={`${className}`} ref={swiperRef}>
      {children}
    </Swiper>
  );
};
